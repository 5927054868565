// TODO: OLD: find out what's needed here. clean up. (taken from old finance)

singlePageTurboLoad = function (e) {
    $(document).on('turbo:load', e);

    $(document).on('turbo:before-render', function () {
        $(document).off('turbo:load', e);
    });
}

singleFrameTurboLoad = function (e) {
    $(document).on('turbo:frame-load', e);

    $(document).on('turbo:before-frame-render', function () {
        $(document).off('turbo:frame-load', e);
    });
}

document.addEventListener("trix-file-accept", function (event) {
    event.preventDefault();
});

$(document).ready(function () {
    // set texarea height on-click/focus to content
    $('.auto-height-textarea').on('focus', function() {
        const $textarea = $(this);
        $textarea.css('height', $textarea.prop('scrollHeight') + 'px');
    });
});

// var ajax_complete_prevent;
$(document).on("turbo:load", function () {
    flatpickr('.datepicker_submit', documentFormFlatpickrOptions())
    autosize(document.querySelectorAll('textarea'));
    $('body').find('input[type^="search"]').first().focus()

    $(".form-error-alert").on("dblclick", function () {
        $(".form-error-alert-full").removeClass("d-none");
    });

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    $('form').each(function () {
        if (!$(this).attr('data-turbo')) {
            $(this).attr('data-turbo', false);
        }
    });

    callFormStylers();

    // $('.modal_loading').click(function () {
    //     setTimeout(function () {
    //         $("body").removeClass("loading");
    //     }, 1000);
    // });
    //
    //     $("a.document_download").attr('target', '_blank');
    $(
        "a:not(.chosen-single):not([target='_blank']):not([role='button']):not([href='#']):not([rel='lightbox']):not([class='search-choice-close']):not([href^='mailto:'])"
    ).click(function () {
        $("body").addClass("loading");
        setTimeout(function () {
            $("body").removeClass("loading");
        }, 3000);
    });

    removeParam = function (key, sourceURL) {
        var rtn = sourceURL.split("?")[0],
            param,
            params_arr = [],
            queryString =
                sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            rtn = rtn + "?" + params_arr.join("&");
        }
        return rtn.includes("?") ? rtn : rtn + "?";
    };
    //
    //     var datepickers = $('input.datepicker');
    //     datepickers.each(function () {
    //         $(this).unbind('dp.change');
    //     });
    //     datepickers.each(function () {
    //         $(this).datetimepicker({
    //             locale: 'de',
    //             format: 'DD.MM.YYYY',
    //             useCurrent: false,
    //             widgetPositioning: {horizontal: 'auto', vertical: 'bottom'},
    //             showClear: true,
    //             showTodayButton: true
    //         }).on('dp.change', function (e) {
    //             $(e.target).trigger('ondblclick');
    //         })
    //     });
    //
    //
    //     var dateranges = $('input.daterange');
    //     dateranges.each(function () {
    //         $(this).daterangepicker({
    //             autoUpdateInput: false,
    //             autoApply: false,
    //             locale: {
    //                 cancelLabel: 'Löschen',
    //                 applyLabel: 'Speichern'
    //             }
    //         });
    //         if ($(this).data('start') && $(this).data('end')) {
    //             $(this).val($(this).data('start') + ' - ' + $(this).data('end'));
    //         }
    //         $(this).unbind('apply.daterangepicker');
    //         $(this).on('apply.daterangepicker', function (ev, picker) {
    //             $(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
    //         });
    //         $(this).unbind('cancel.daterangepicker');
    //         $(this).on('cancel.daterangepicker', function (ev, picker) {
    //             $(this).val('');
    //         });
    //     });
    //
    //
    $(".preview_document").each(function () {
        $(this).height(Math.min($(window).height() - 100, $(this).width() * 1.44));
    });
    //
    $("table")
        .not(".notable")
        .not("#pivot table")
        .not(".orb table")
        .not("table.orb")
        .not(".orb-overlay table")
        .each(function () {
            $(this).addClass("table table-condensed table-hover table-striped");
        });

    $(".no-table-hover").each(function () {
        $(this).removeClass("table-hover");
    });
    $(".no-table-striped").each(function () {
        $(this).removeClass("table-striped");
    });
    $(".table-large").each(function () {
        $(this).removeClass("table-condensed");
    });
    //
    //     $("body").removeClass('loading');
    //
    $(document).unbind("ajax:success");
    $(document).on("ajax:success", ".delete-ajax", function () {
        eval($(this).data("callbackok"));
    });

    var ajax_forms = $("form.ajax_form");
    ajax_forms.unbind("submit");
    ajax_forms.on("submit", function () {
        var obj = $(this);
        $.ajax({
            method: obj.attr("method"),
            url: obj.data("url"),
            data: obj.serialize(),
        }).done(function (data) {
            if (data.status == "ok") {
                alertify.success(data.message);
                eval(obj.data("callbackok"));
                if (data.remove) $(obj).closest("tbody").fadeOut();
                if (data.posting_status) {
                    $("#finance_document_" + data.document_id + "_posting_status").html(
                        data.posting_status
                    );
                }
            } else {
                alertify.error(data.message);
                obj[0].reset();
                obj.find("select").trigger("chosen:updated");
                eval(obj.data("callbackerror"));
            }
        });
        return false;
    });

    var select_docs = $("input.export");
    select_docs.change(function () {
        $("span#document_count").html(select_docs.serializeArray().length);
    });
    select_docs.trigger("change");
    //
    //     var filter_form = $("#filter_form");
    //
    //     filter_form.submit(function () {
    //         $(this).find(":input").filter(function () {
    //             return !this.value;
    //         }).attr("disabled", "disabled");
    //         return true; // ensure form still submits
    //     });
    //
    //     // Un-disable form fields when page loads, in case they click back after submission
    //     filter_form.find(":input").prop("disabled", false);
    //
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover({trigger: "hover", html: true});

    $('.toggle-checkbox').on('change', function() {
        var parentDiv = $(this).closest('.toggle-field');
        var additionalField = parentDiv.find('.toggle-data');

        if ($(this).prop('checked')) {
            additionalField.show();
        } else {
            additionalField.hide();
        }
    });

    if (refreshIntervalId == null) {
        fetchLiveCounts();
        refreshIntervalId = setInterval(fetchLiveCounts, 30 * 1000);
    } else {
        fetchLiveCounts();
    }
    //
    //     var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    //     if (width < 768)
    //         $('a').each(function () {
    //             if (!/mobile=true$/i.test($(this).attr('href'))) {
    //                 $(this).attr('href', $(this).attr('href') + (/\?/i.test($(this).attr('href')) ? '&' : '?') + "mobile=true");
    //             }
    //         });
    //
    //     // 2 Digit Precision
    //     var money_inputs = $('input.money_format');
    //     money_inputs.unbind('focus');
    //     money_inputs.on('focus', function () {
    //         if ($(this).val() != "") {
    //             $(this).val(parseFloat($(this).val().replace(/[^0-9.\-]/g, "")));
    //             $(this).select();
    //         }
    //     });
    //     money_inputs.unbind('blur');
    //     money_inputs.on('blur', function () {
    //         if ($(this).val() != "") {
    //             $(this).val($.number(parseFloat($(this).val().replace(/[^0-9.\-]/g, "")), 2, '.', '\''));
    //         }
    //     });
    //     money_inputs.trigger('blur');
    //
    //     // 4 Digits of precision
    //     var money_inputs_extended = $('input.money_format_extended');
    //     money_inputs_extended.unbind('focus');
    //     money_inputs_extended.on('focus', function () {
    //         if ($(this).val() != "") {
    //             $(this).val(parseFloat($(this).val().replace(/[^0-9.\-]/g, "")));
    //             $(this).select();
    //         }
    //     });
    //     money_inputs_extended.unbind('blur');
    //     money_inputs_extended.on('blur', function () {
    //         if ($(this).val() != "") {
    //             $(this).val($.number(parseFloat($(this).val().replace(/[^0-9.\-]/g, "")), 4, '.', '\''));
    //         }
    //     });
    //     money_inputs_extended.trigger('blur');
    //
    //
    $(".sortable").each(function (index) {
        $(this).sortable({
            handle: ".handle",
            opacity: 0.5,
            items: "> .sort",
            axis: "y",
        });
        $(this).on("sortupdate", function (event, ui) {
            var i = 1000;
            $(ui.item.closest(".sortable"))
                .find("input.order_string")
                .each(function () {
                    $(this).val(i);
                    i++;
                });
        });
    });
    //
    //     $(".make_link").click(function () {
    //         document.location = $(this).data("href");
    //     });
    //
    $("body").on("cocoon:after-insert", function () {
        $(".sortable").each(function () {
            $(this).trigger("sortupdate", [{item: $(this)}]);
        });
    });
    replaceAjaxFormListener();
    //
    //     focused.focus();
});
replaceAjaxFormListener = function () {
    var ajax_forms = $("form.ajax_form");
    ajax_forms.unbind("submit");
    ajax_forms.on("submit", function () {
        var obj = $(this);
        $.ajax({
            method: obj.attr("method"),
            url: obj.data("url"),
            data: obj.serialize(),
        }).done(function (data) {
            if (typeof data === "string") {
                return;
            }
            if (data.status == "ok") {
                alertify.success(data.message);
                eval(obj.data("callbackok"));
                if (data.remove) $(obj).closest("tbody").fadeOut();
                if (data.posting_status) {
                    $("#document_" + data.document_id + "_posting_status").html(
                        data.posting_status
                    );
                }
            } else {
                alertify.error(data.message);
                obj[0].reset();
                obj.find("select").trigger("chosen:updated");
                eval(obj.data("callbackerror"));
            }
            fetchLiveCounts();
        });
        return false;
    });
};

toggleElement = function (elementId) {
    const element = $('#' + elementId);
    if (element.is(':hidden')) {
        element.show();
    } else {
        element.hide();
    }
};

//
var refreshIntervalId = null;
//
// $(document).ajaxComplete(function (event) {
//     if (!ajax_complete_prevent) {
//         ready();
//     }
//     ajax_complete_prevent = false;
// });
// $(document).ready(ready);
// $(document).on('turbolinks:load', ready);
// $(document).on('cocoon:after-insert', ready);
//
//
visaAccept = function (object, visa_id, table) {
    $.post(langAdderToUrl("/finance/visas/" + visa_id + "/accept")).done(function (data) {
        if (data.status == "ok") {
            $(object).closest("tr").addClass("success");
            alertify.success("Status: " + data.visa.status);
            table
                ? $(object).closest("tr").fadeOut()
                : $(object).closest(".visa_view").fadeOut();
            if (!data.hide_modal) {
                documentHistory(data.visa.document.id);
            }
        } else {
            $(data.message).each(function (key, value) {
                alertify.error(value);
            });
        }
    });
};

visaReject = function (object, visa_id, table) {
    reason_string = prompt("Bitte geben Sie einen Grund an:");
    if (reason_string && reason_string != " " && reason_string != "") {
        $.post(langAdderToUrl("/finance/visas/" + visa_id + "/reject"), {
            reason: reason_string,
        }).done(function (data) {
            if (data.status == "ok") {
                $(object).closest("tr").addClass("danger");
                alertify.success("Status: " + data.visa.status);
                table
                    ? $(object).closest("tr").fadeOut()
                    : $(object).closest(".visa_view").fadeOut();
                documentHistory(data.visa.document.id);
            } else {
                $(data.message).each(function (key, value) {
                    alertify.error(value);
                });
            }
        });
    } else alertify.error("Es muss ein Grund angegeben werden.");
};

visaRedirect = function (object, visa_id, table) {
    $.post(langAdderToUrl("/finance/visas/" + visa_id + "/redirect"), {
        user_id: $(object).val(),
    }).done(function (data) {
        if (data.status == "ok") {
            $(object).closest("tr").addClass("info");
            alertify.success("Umgeleitet: " + data.visa.user.title);
            table
                ? $(object).closest("tr").fadeOut()
                : $(object).closest(".visa_view").fadeOut();
        } else {
            $(data.message).each(function (key, value) {
                alertify.error(value);
            });
            $(object).val("").trigger("chosen:updated");
        }
    });
};

documentHistory = function (document_id) {
    $("body").addClass("loading");
    $.get(langAdderToUrl("/finance/documents/" + document_id + "/history")).done(function (data) {
        modal(data, I18n.t("js.finance_documents.history.header"), "", I18n.t("js.finance_documents.action.close"));
        $("body").removeClass("loading");
    });
};

documentHistoryNonRemote = function (document_id) {
    $("body").addClass("loading");
    $.get(langAdderToUrl("/finance/documents/" + document_id + "/history?prevent_remote=1")).done(
        function (data) {
            modal(data, "Verlauf", "", "Schliessen");
            $("body").removeClass("loading");
        }
    );
};
//
documentPosting = function (document_id) {
    $("body").addClass("loading");
    $.get(langAdderToUrl("/finance/documents/" + document_id + "/posting")).done(function (data) {
        modal(data, "Buchung", "", "Schliessen");
        $("body").removeClass("loading");
    });
};

//
function loadPostingAndFocus(document_id, path) {
    $("#finance_posting_" + document_id).load(path, function () {
        $("#finance_posting_" + document_id)
            .find("select")
            .chosen();
    });
}

//
// function modal(content, title, button_save, button_dismiss) {
//     $("#modal-body").html(content);
//     if (title != "") {
//         $("#modal-title").html(title);
//         $("#modal-title").show();
//     }
//     else $("#modal-title").hide();
//
//     if (button_dismiss != "") {
//         $("#modal-dismiss").html(button_dismiss);
//         $("#modal-dismiss").show();
//     }
//     else $("#modal-dismiss").hide();
//
//     if (button_save != "") {
//         $("#modal-save").html(button_save);
//         $("#modal-save").show();
//     }
//     else $("#modal-save").hide();
//
//     $("#modal").modal('show');
// }
//
// function modalClose() {
//     if ($("#modal").find("input[type='submit']")) {
//         $("#modal").find("input[type='submit'].modal_click").click();
//     }
//     else {
//         $("#modal").modal('hide');
//     }
//
// }
//

doSelectedDocuments = function (action) {
    if ($("input.export").serializeArray().length > 0) {
        $("#document_action").val(action);
        if (action == "pay") {
            $("#pay_date").val(prompt("Zu setzendes Datum eingeben: (DD.MM.YYYY)"));
        } else if (action == "tag") {
            $("#tag_id").val($("#tag_tag_id").val());
        } else if (action == "budget") {
            $("#budget_id").val($("#budget_budget_id").val());
            $("#user_id").val($("#budget_user_id").val());
        }
        if (confirm("Sind sie sicher?")) {
            $("#select_documents").submit();
        }
    } else {
        alertify.error("Keine Dokumente ausgewählt!");
        $("#tag_tag_id").val("");
        $("#tag_tag_id").trigger("chosen:updated");
    }
};
//
//
editSelectedDocuments = function () {
    if ($("input.export").serializeArray().length > 0) {
        var str = JSON.stringify($("input.export").serializeArray());
        object = JSON.parse(str);
        location.href =
            location.origin +
            langAdderToUrl("/finance/documents/edit_index_again") +
            "?" +
            $.param(object);
    } else alertify.error("Keine Dokumente ausgewählt!");
};

filterSelectedDocuments = function () {
    if ($("input.export").serializeArray().length > 0) {
        var ids = $("input.export")
            .serializeArray()
            .map(function (ele) {
                return "query%5Bid_in%5D%5B%5D=" + ele.value;
            })
            .join("&");
        location.href =
            removeParam("query%5Bid_in%5D%5B%5D", location.href) + "&" + ids;
    }
};
//
// function setTagSelectedDocuments() {
//
// }
//
// var last_fetchAllExternalSource = 0;
//
// function fetchAllExternalSources(ele) {
//     if (last_fetchAllExternalSource < Date.now() - 180000) {
//         $.post("/external_sources/fetch_all")
//             .done(function (data) {
//                 if (data.status == 'ok') {
//                     alertify.success(data.message);
//                 }
//                 else {
//                     alertify.error(data.message);
//                 }
//             });
//         last_fetchAllExternalSource = Date.now();
//     }
//     else alertify.error("Bitte warten ...", 1);
//     return false;
// }
//
// function removeParam(key, sourceURL) {
//     var rtn = sourceURL.split("?")[0],
//         param,
//         params_arr = [],
//         queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
//     if (queryString !== "") {
//         params_arr = queryString.split("&");
//         for (var i = params_arr.length - 1; i >= 0; i -= 1) {
//             param = params_arr[i].split("=")[0];
//             if (param === key) {
//                 params_arr.splice(i, 1);
//             }
//         }
//         rtn = rtn + "?" + params_arr.join("&");
//     }
//     return rtn;
// }
//
//
// function filterByCollection(obj) {
//     $("body").addClass("loading");
//     location.href = removeParam($(obj).serialize().split('=')[0], location.href) +
//         (/\?/i.test(location.href) ? (/&$/i.test(location.href) ? "" : '&') : '?') +
//         ($(obj).val() == "" ? "" : $(obj).serialize());
// }
//
// function filterByText(obj) {
//     $("body").addClass("loading");
//     location.href = removeParam($(obj).serialize().split('=')[0], location.href) +
//         (/\?/i.test(location.href) ? (/&$/i.test(location.href) ? "" : '&') : '?') +
//         ($(obj).val() == "" ? "" : $(obj).serialize());
// }
//
numToCurrency = function (num) {
    class_name = num < 0 ? "danger" : "success";

    //return "<span class='text-" + class_name + "'>CHF " + $.number(num, 2, '.', '\'') + "</span>";
    return $.number(num, 2, ".", "'");
};

function fetchLiveCounts() {
    // Determine view
    var path = null;
    if ($("div#debit").length > 0) {
        path = "/finance/live/counts_debit.json";
    } else if ($("div#credit").length > 0) {
        path = "/finance/live/counts_credit.json";
    } else if ($("div#finance").length > 0) {
        path = "/finance/live/counts_finance.json";
    } else if ($("div#crm").length > 0) {
        path = "/finance/live/counts_crm.json";
    }

    if (path != null) {
        $.post(path)
            .done(function (data) {
                if (data.status == "ok") {
                    for (var key in data.values) {
                        if (data.values.hasOwnProperty(key)) {
                            var ele = $("." + key + "_live");
                            if (ele.length > 0) {
                                var count = data.values[key];
                                ele.text(count);

                                if (count != 0) {
                                    $(ele).removeClass("badge-success");
                                    $(ele).addClass("badge-danger");
                                } else {
                                    $(ele).addClass("badge-success");
                                    $(ele).removeClass("badge-danger");
                                }
                            }
                        }
                    }
                }
            })
            .fail(function () {
                clearInterval(refreshIntervalId);
            })
            .always(function () {
                ajax_complete_prevent = true;
            });
    }
}

// $(document).on('turbolinks:load', ready);

flatpickrOptionsDatepicker = function () {
    return {
        allowInput: true,
        dateFormat: "d.m.Y",
        // the date needs to be validated twice, because one is for input and the other is for the format when saved
        parseDate: function (date) {
            return moment(date, [
                'DD.MM.YYYY',
                'D.M.YYYY',
                'D.M.YY',
                'YYYY.MM.DD',
                'YYYY-MM-DD',
                'DD. MMMM YYYY',
            ], true)._d
        },
        plugins: [
            ShortcutButtonsPlugin({
                button: [
                    {
                        label: I18n.t("js.flatpickr.today"),
                    },
                    {
                        label: I18n.t("js.flatpickr.clear"),
                    },
                ],
                onClick: (index, fp) => {
                    let date = new Date();
                    switch (index) {
                        case 0:
                            fp.setDate(date, true);
                            fp.close();
                            break;
                        case 1:
                            fp.clear();
                            fp.close();
                            break;
                    }
                },
            }),
        ],
    }
}

flatpickrOptionsDatetimepicker = function () {
    return {
        time_24hr: true,
        enableTime: true,
        altInput: true,
        altFormat: "d.m.Y, H:i",
        dateFormat: "Y.m.d H:i",
    }
}

callFlatpickrJS = function () {
    if (I18n.currentLocale == "de-CH") flatpickr.localize(German);

    // To fix the inputs getting duplicated when going back then forward on a page
    $(".input.datepicker").remove();
    $(".input.datetimepicker").remove();

    flatpickr(".datepicker", flatpickrOptionsDatepicker());
    flatpickr(".datetimepicker", flatpickrOptionsDatetimepicker());
    flatpickr(".timepicker", {
        time_24hr: true,
        disableMobile: "true",
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
    });
};

callChosenJS = function (ignoreEmpty = true) {
    $(".chosen-select").each(function () {
        if (ignoreEmpty && $(this).css("display") === "none") return;
        var select, chosen;
        // Cache the select element as we'll be using it a few times
        select = $(this);
        // Init the chosen plugin
        select.chosen({
            allow_single_deselect: true,
            placeholder_text_single: ' ',
            placeholder_text_multiple: ' ',
            width: "100%",
            search_contains: true,
            no_results_text: ' '
        });
        // Get the chosen object
        chosen = select.data("chosen");
        if (select.closest("form").hasClass("assoc_creatable")) {
            // Bind the keyup event to the search box input
            var func = function (e) {
                if (e.which == 13 && chosen.dropdown.find("li.no-results").length > 0) {
                    if (
                        confirm(I18n.t("js.chosen.new_category", {catgory: this.value}))
                    ) {
                        var data = {};
                        data[select.closest("form").data("creatable-object")] = {
                            company: this.value,
                        };
                        data["assoc"] = true;
                        $.ajax({
                            method: "POST",
                            url: select.closest("form").data("creatable-url"),
                            data: data,
                        }).done(function (data) {
                            if (data.status == "ok") {
                                a;
                                alertify.success(data.message);
                                var option = $(
                                    "<option value='" +
                                    data[select.closest("form").data("creatable-object")].id +
                                    "'>"
                                ).text(
                                    data[select.closest("form").data("creatable-object")].title
                                );
                                // Add the new option to every select of same name
                                $(
                                    "select.chosen-select[name='" + select.attr("name") + "']"
                                ).each(function () {
                                    $(this).append(option.clone());
                                    $(this).trigger("chosen:updated");
                                });
                                select.prepend(option);
                                // Automatically select it
                                select.find(option).prop("selected", true);
                                // Trigger the update
                                select.trigger("chosen:updated");
                                select.trigger("change");
                            } else {
                                alertify.error(data.message);
                                select[0].reset();
                                select.find("select").trigger("chosen:updated");
                            }
                        });
                    }
                }
            };
            chosen.dropdown.find("input").unbind("keydown.assoc_creatable");
            chosen.dropdown.find("input").on("keydown.assoc_creatable", func);
        }
    });

    $.each($(".chosen-update"), function (_k, v) {
        e = $(v);
        e.val(e.attr("value"));
        e.trigger("chosen:updated");
        e.removeClass("chosen-update");
    });
};

callFormStylers = function () {
    callChosenJS();
    callFlatpickrJS();
};

modal = function (content, title, button_save, button_dismiss, button_delete) {
    $("#modal-body").html("");
    $("#modal-body").html(content);
    if (title != "") {
        $("#modal-title").html(title);
        $("#modal-title").show();
    } else $("#modal-title").hide();

    if (button_dismiss != "") {
        $("#modal-dismiss").html(button_dismiss);
        $("#modal-dismiss").show();
    } else $("#modal-dismiss").hide();

    if (button_save != "") {
        $("#modal-save").html(button_save);
        $("#modal-save").show();
    } else $("#modal-save").hide();

    if (button_delete != "") {
        $("#modal-delete").html(button_delete);
        $("#modal-delete").show();
    } else $("#modal-delete").hide();

    if ($("#modal").find("a.delete_link").length == 0) {
        $("#modal-delete").hide();
    }

    $("#modal").modal("show");
    setTimeout(function () {
        $("#modal").find("input:not([type='hidden'])")[0]?.focus();
    }, 600);
    callFormStylers();
};

modalClose = function (checkForm = true) {
    if (checkForm && $("#modal").find("form").length > 0) {
        $("#modal").find("input[type='submit']").click();
        $("body").addClass("loading");
    } else {
        $("#modal").modal("hide");
        $("#modal-body").empty();
    }
};

modalDelete = function () {
    if ($("#modal").find("a.delete_link").length > 0) {
        $("#modal").find("a.delete_link")[0].click();
        $("#modal").modal("hide");
        $("#modal-body").empty();
    }
};

copyToClipboard = function (text, target, doAlert = false) {
    var ele = $('<textarea class="form-control"/>')
        .val(text)
        .attr("readonly", "readonly");
    $(target).append(ele);
    $(ele).select();
    document.execCommand("copy");
    $(ele).remove();
    if (doAlert) {
        alertify.success(I18n.t("js.copyToClipboard"));
    }
};

documentFormFlatpickrOptions = function () {
    return {
        allowInput: true,
        altFormat: "d.m.Y",
        dateFormat: "d.m.Y",
        parseDate: function (date) {
            return moment(date, [
                'DD.MM.YYYY',
                'D.M.YYYY',
                'D.M.YY',
                'YYYY.MM.DD',
                'YYYY-MM-DD',
                'DD. MMMM YYYY',
            ], true)._d
        },
        onChange: function (_a, _b, flatpickr_instance) {
            $(flatpickr_instance.element).closest("form").get()[0].requestSubmit();
        },
        plugins: [
            ShortcutButtonsPlugin({
                button: [
                    {
                        label: I18n.t("js.flatpickr.today"),
                    },
                    {
                        label: I18n.t("js.flatpickr.clear"),
                    },
                ],
                onClick: (index, fp) => {
                    let date = new Date();
                    switch (index) {
                        case 0:
                            fp.setDate(date, true);
                            fp.close();
                            break;
                        case 1:
                            fp.clear();
                            fp.close();
                            break;
                    }
                },
            }),
        ],
    };
};

documentFormUpdatePicker = function (id, date) {
    flatpickr(id, documentFormFlatpickrOptions()).setDate(date);
    setTimeout(function () {
        $(id).closest("form").get()[0].requestSubmit();
    }, 100); // timing issue with the form not submitting the updated date
};

documentFormSetDateToday = function (id) {
    d = new Date();
    documentFormUpdatePicker(id, d);
};

documentFormSetDateYesterday = function (id) {
    d = new Date();
    d.setDate(d.getDate() - 1);
    documentFormUpdatePicker(id, d);
};

documentFormAddDays = function (id, days, other) {
    d = new Date(other.val().split('.').reverse().join('-'));
    d.setDate(d.getDate() + days);
    documentFormUpdatePicker(id, d);
};

highlight = function (searchText) {
    searchText = searchText.toLowerCase();
    let inputTexts = document.getElementsByClassName("highlight_area");

    // for text highlighting
    var mark = function () {
        $(".highlight_area").unmark({
            done: function () {
                $(".highlight_area").mark(searchText);
            }
        });
    };

    mark();

    // for tooltip highlighting
    inputTexts.forEach((e) => {
        searchText.split(" ").forEach((word) => {
            highlightTooltipAndChildren($(e), word.toLowerCase());
        });
    });

    let highlightParents = document.getElementsByClassName("highlight_parent");
    highlightParents.forEach((highlightParent) => {
        if (highlightParent.innerHTML.includes("class='highlight'")) {
            highlightParent.classList.add("highlight");
        }
    });
};

highlightTooltipAndChildren = function (element, searchText) {
    if (element.length === 0) {
        return
    }

    highlightTooltip(element, searchText)

    if (element.children().length > 0) {
        element.children().each(function (i, child) {
            highlightTooltipAndChildren($(child), searchText);
        });
    }
}

highlightTooltip = function (element, searchText) {
    var oldText;

    if (element.attr('data-toggle') === "tooltip") {
        oldText = element.attr('title')

        if (!oldText) {
            oldText = element.attr('data-original-title')
        }
    } else {
        oldText = element.clone().children().remove().end().text()
    }

    var index = oldText.toLowerCase().indexOf(searchText);

    if (element.attr('data-toggle') === "tooltip" && index >= 0 && searchText) {
        element.parent().addClass('highlight')
    } else {
        element.parent().removeClass('highlight')
    }
}

accessBackendBookingAddAllocation = function () {
    const rowTempalte = $("#template_row");
    const container = $("#allocation_container");
    const accessBackendBookingCount = accessBackendBookingCurrentxId() + 1;

    let newRow = rowTempalte.clone();
    newRow.prop("id", `allocation_${accessBackendBookingCount}`);
    let newAllocation = newRow.find("#allocation_template");
    let newPrice = newRow.find("#price_template");
    let newCount = newRow.find("#count_template");
    let newButton = newRow.find("#template_delete");
    let newNote = newRow.find("#note_template");
    let newNoteScanning = newRow.find("#note_scanning_template");
    let newRemoveButton = newRow.find("#template_delete");

    newAllocation.prop("id", `allocation_id_${accessBackendBookingCount}`);
    newAllocation.removeClass("d-none");
    newAllocation.find("select").addClass("chosen-select");
    newAllocation.find("select").prop("name", `${accessBackendBookingCount}[id]`);

    newPrice.prop("id", `allocation_price_${accessBackendBookingCount}`);
    newPrice.removeClass("d-none");
    newPrice.find("input").prop("name", `${accessBackendBookingCount}[price]`);

    newAllocation
        .find("select")
        .on("change", accessBackendBookingSelectChangeListener);

    newCount.prop("id", `allocation_count_${accessBackendBookingCount}`);
    newCount.removeClass("d-none");
    newCount.find("input").prop("name", `${accessBackendBookingCount}[count]`);

    newButton.prop("id", "");
    newButton.removeClass("d-none");
    newButton.click(
        accessBackendBookingCount,
        accessBackendBookingRemoveAllocation
    );

    newNote.prop("id", `note_${accessBackendBookingCount}`);
    newNote.removeClass("d-none");
    newNote.find("input").prop("name", `${accessBackendBookingCount}[note]`);

    newNoteScanning.prop("id", `note_scanning_${accessBackendBookingCount}`);
    newNoteScanning.removeClass("d-none");
    newNoteScanning.find("input").prop("name", `${accessBackendBookingCount}[note_scanning]`);

    newRemoveButton.prop("id", "");
    newRemoveButton.removeClass("d-none");
    newRemoveButton.addClass("d-flex");

    newRow.find("hr").removeClass("d-none");

    newRow.find(".discount-template").removeClass("d-none");
    newRow.find(".template-discount").each(function () {
        $(this).attr(
            "onclick",
            `setPrice('${accessBackendBookingCount}', ${this.innerHTML})`
        );
        this.innerHTML = this.innerHTML + "%";
    });
    newRow
        .find(".template-discount-free")
        .attr("onclick", `setFreePrice('${accessBackendBookingCount}')`);

    container.append(newRow);
    accessBackendBookingUpdateCount();
    callFormStylers();
};

accessBackendBookingSelectChangeListener = function (evt, params) {
    try {
        $(evt.currentTarget)
            .parent()
            .parent()
            .parent()
            .find(`.apit`)
            .val((params?.selected?.split(";") || [])[1]);
    } catch (e) {
    }
    try {
        $(evt.currentTarget).parent().parent().parent().find(`.apt`)[0].innerHTML =
            (params?.selected?.split(";") || [])[1];
    } catch (e) {
    }
};

accessBackendBookingCurrentxId = function () {
    let allocationContainer = $("#allocation_container");
    let countArray = [];

    allocationContainer.children().each(function () {
        countArray.push($(this).attr("id").replace("allocation_", ""));
    });

    if (countArray.length === 0) {
        return 0;
    } else {
        return Math.max(...countArray);
    }
};

accessBackendBookingRemoveAllocation = function (event) {
    if (typeof event != "object") {
        event = {data: event};
    }
    let allocation = $("#allocation_container").find(`#allocation_${event.data}`);
    allocation.remove();
    accessBackendBookingUpdateCount();
};

accessBackendBookingUpdateCount = function () {
    let allocationContainer = $("#allocation_container");
    let allocationCounter = $("#template_count");
    let countArray = [];

    allocationContainer.children().each(function () {
        countArray.push($(this).attr("id").replace("allocation_", ""));
    });

    allocationCounter.val(countArray.join(","));
};

number_to_currency = function (value, currency, replaceCentsZero = true) {
    let valueInCurrency = value
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1'`)

    if (replaceCentsZero) {
        valueInCurrency = valueInCurrency.replace(/\.00/, ".-");
    }

    return `${currency} ${valueInCurrency}`;
};

modalFromPath = function (
    path,
    method,
    title = "",
    button_save = "",
    button_dismiss = "",
    button_delete = ""
) {
    modalClose();
    $("body").addClass("loading");
    var request;

    switch (method) {
        case "get":
            request = $.get(path);
            break;
        case "post":
            request = $.post(path);
            break;
        default:
            return;
    }

    request.done(function (data) {
        modal("", title, button_save, button_dismiss, button_delete);
        $("body").removeClass("loading");
    });
};

((/** set title of the document **/) => {
    const options = {
        seperator: " - ",
        reverseTitleOrder: false,
        interceptXMLHttp: true,
    };

    // Get
    const getCurrentDocumentTitle = () => document.title.split("-").shift();
    const getCurrentNavbarTitle = () =>
        document.querySelector(".navbar-brand")?.innerText || "";
    const getCurrentPageTitle = () =>
        document.querySelector("h1")?.innerText?.split("\n")?.shift() || "";

    // Set
    const setDocumentTitle = () => {
        // load current page title
        const pageTitle = getCurrentPageTitle();
        const moduleTitle = getCurrentNavbarTitle()
            ? getCurrentNavbarTitle()
            : getCurrentDocumentTitle();

        // Set new pageTitle if pageTitle is not empty
        document.title = pageTitle
            ? [moduleTitle, pageTitle].join(options.seperator)
            : moduleTitle;

        if (options.reverseTitleOrder)
            document.title = document.title
                .split(options.seperator)
                .reverse()
                .join(options.seperator);
    };

    // Inti
    document.addEventListener("DOMContentLoaded", setDocumentTitle);

    // Interceptor
    // Rails is loading page content by xmlhttprequest
    // Intercept and call setDocumentTitle
    if (options.interceptXMLHttp) {
        (function () {
            var origOpen = XMLHttpRequest.prototype.open;
            XMLHttpRequest.prototype.open = function () {
                const onContendLoaded = () => {
                    setDocumentTitle();
                    this.removeEventListener("load", (ev) => onContendLoaded());
                };
                this.addEventListener("load", (ev) => onContendLoaded());
                origOpen.apply(this, arguments);
            };
        })();
    }
})();

$(document).on('turbo:render', function () {
    callFormStylers()
});
$(document).on('turbo:frame-render', function () {
    callFormStylers()
})

$("*").on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
    $(insertedItem).find('.custom-file-uploader').each(function () {
        var keyDiv = $(this).parent().parent().find('.custom-file-uploader-key');
        var date = Date.now();

        if (!keyDiv.attr('key').endsWith('updated')) {
            keyDiv.attr('key', `${keyDiv.attr('key')}_${date}_updated`);
            keyDiv.attr('value', keyDiv.attr('key'));
            keyDiv.attr('full-key', keyDiv.attr('key'));
        }

        initFileUploader($(this).parent().parent(), `eventon_files_${keyDiv.attr('key')}`);
    })
});

$(document).on('turbo:visit', function () {
    idb.entries().then(function (entries) {
        for (const [k, v] of entries) {
            if (k.startsWith('eventon_files_') && v.date + 1000 * 60 * 60 < Date.now()) {
                idb.del(k);
            }
        }
    });
});

initFileUploader = function (input, fileKey) {
    var element = input;
    if (element.prop("tagName") === 'HEAD') {
        return;
    }

    if (!element.hasClass('file-uploader-wrapper')) {
        element = element.find('.file-uploader-wrapper');
    }

    if (element.length === 0) {
        return;
    }

    var uploader = element.find('input');
    var uploaderButton = element.find('#uploader-label');
    var downloadLink = element.find('#file-downloader');
    var preview = element.find('.img-preview');
    var deleteIcon = element.find('#deleteIcon');
    var fieldset = element.find('fieldset.boolean');
    var fileName = element.find('#file-name');
    var img = preview.children().first();

    var inputData = element.find('#input-data');
    var type = inputData.attr('type');
    var acceptedTypes = inputData.attr('accepted-types');

    uploader.css('display', 'none');

    if (fieldset.length > 0) {
        fieldset.css('display', 'none');
        fieldset.find('.form-check-input').attr('value', null);
        fieldset.find('.form-check-input').prev().attr('value', null);
    }

    deleteIcon.on('click', function () {
        uploader.onclear();
        uploader.show();
        deleteIcon.css('display', 'none');
    })

    uploader.onclear = () => {
        idb.del(fileKey)

        uploader.attr('value', null);
        img.attr('src', '');
        fileName.text('');
        if (fieldset) {
            fieldset.find('.form-check-input').attr('value', '1');
            fieldset.find('.form-check-input').prev().attr('value', '1');
        }
    }

    uploader.hide = () => {
        uploaderButton.css('display', 'none');
        preview.css('display', 'flex');
        deleteIcon.css('display', 'block');
    }

    uploader.show = () => {
        uploaderButton.css('display', 'block');
        preview.css('display', 'none');
        downloadLink.css('display', 'none');
        deleteIcon.css('display', 'none');
        fileName.css('display', 'none');
    }

    uploader.on('change', function () {
        if (!$(this).prop('files')) {
            return;
        }

        const files = $(this).prop('files');
        const [file] = files;

        if (file) {
            uploader.hide();
            img.attr('src', URL.createObjectURL(file));
            if (fieldset) {
                fieldset.find('.form-check-input').attr('value', null);
                fieldset.find('.form-check-input').prev().attr('value', null);
            }
            fileName.text(file.name);

            downloadLink.css('display', 'none');
            fileName.css('display', 'block');
            idb.set(fileKey, {date: Date.now(), file: file})
        }
    })

    if (type === 'image') {
        uploader.attr('accept', 'image/*');
    }

    if (acceptedTypes) {
        uploader.attr('accept', acceptedTypes);
    }

    if (uploader.data('src')) {
        if (type === 'image') {
            img.on('load', function () {
                uploader.hide();
            });

            img.on('error', function () {
                uploader.show();
            });

            img.attr('src', uploader.data('src'));
        } else {
            uploader.hide();
        }
    }

    if (parseInt(isFirstFormLoadStatus) >= 400 && parseInt(isFirstFormLoadStatus) <= 499) {
        idb.get(fileKey).then(function (file) {
            if (file) {
                let fileList = new DataTransfer();
                fileList.items.add(file.file);
                uploader.prop('files', fileList.files);
                uploader.trigger('change');
            }
        });
    } else {
        idb.del(fileKey);
    }

    uploader.css('cursor', 'pointer');
}

langAdderToUrl = function (url) {
    return ("/" + I18n.locale.replaceAll("-CH", "") + url)
}

numberClean = function (num) {
    return parseFloat((num).toString().replace(/[^-0-9.]/g, '').replace(/(?!^)-/g, ''))
}

numberDelim = function (num, fractionDigits = 2) {
    if (isNaN(num)) {
        num = 0
    }
    try {
        // Hardcoded de-CH locale because previously the formatting was hardcoded to the de-CH formatting too.
        // TODO: Maybe make this locale dependent, requires a big overhaul in other places too though.
        return new Intl.NumberFormat('de-CH', {minimumFractionDigits: fractionDigits}).format(num.toFixed(fractionDigits));
    } catch (error) {
        console.error(error);
        return num;
    }
}

$.fn.pulse = function (options) {
    var options = $.extend({
        times: 3,
        duration: 1000
    }, options);

    var period = function (callback) {
        $(this).animate({opacity: 0}, options.duration, function () {
            $(this).animate({opacity: 1}, options.duration, callback);
        });
    };
    return this.each(function () {
        var i = +options.times, self = this,
            repeat = function () {
                --i && period.call(self, repeat)
            };
        period.call(this, repeat);
    });
};

$("*").on('cocoon:after-insert', callFormStylers);

simpleTableSearch = function (tableId, filter) {
    var input, table, txtValue;
    input = document.getElementById("myInput");
    filter = filter.toUpperCase();
    table = document.getElementById(tableId);
    trs = $(`#${tableId} tr.searchable`);
    trs.toArray().forEach((tr) => {
        found = false
        $(tr).find('*').toArray().forEach((element) => {
            if (element && !found) {
                txtValue = element.textContent || element.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr.style.display = "";
                    found = true
                    return
                } else {
                    tr.style.display = "none";
                }
            }
        })
    })
}

enableLoadingSpinner = function () {
    $('#turbo-loading-spinner').removeClass('d-none');
}

disableLoadingSpinner = function () {
    $('#turbo-loading-spinner').addClass('d-none');
}

// Generate random hex string
genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')

multiSelectChosen = function (selector, objectId, initializeArray, activeForCloneIds) {
    initializeArray.forEach((optionCount) => {
        let option = $(`.${selector}[object-id="${objectId}"]`).find(`option[value="${optionCount[0]}"]`);
        for (let i = 0; i < optionCount[1]; i++) {
            let clone = option.clone().attr("clone", true)
            if (i == optionCount[1] - 1) {
                if (activeForCloneIds.includes(optionCount[0])) {
                    option.after(clone.attr("selected", false).appendTo(option));
                }
            } else {
                option.after(clone.attr("selected", true));
            }
        }
    });

    singlePageTurboLoad(function () {
        $(`.${selector}[object-id="${objectId}"]`).chosen().change(function (event) {
            let currentDataSet = $(event.target).val();
            let target = $(event.target)

            target.find("option[clone=true]").filter(function () {
                return activeForCloneIds.includes(parseInt($(this).val()));
            }).remove()

            currentDataSet.forEach(function (selectedOptionValue) {
                if (activeForCloneIds.includes(parseInt(selectedOptionValue))) {
                    let tag = target.find(`option[value="${selectedOptionValue}"]:not([clone="true"])`);
                    tag.after(tag.clone().attr("selected", true).attr("clone", true));
                }
            })

            activeOptions = target.find(`option:not([clone="true"])`).filter(function () {
                return activeForCloneIds.includes(parseInt($(this).val()));
            })

            activeOptions.removeAttr("selected");
            activeOptions.prop("selected", false);

            target.trigger("chosen:updated");
        });
    });

    $(document).on('cocoon:after-insert', function () {
        $(`.${selector}[object-id="${objectId}"]`).chosen().change(function (event) {

            let currentDataSet = $(event.target).val();
            let target = $(event.target)

            target.find("option[clone=true]").remove()

            currentDataSet.forEach(function (selectedOptionValue) {
                let tag = target.find(`option[value="${selectedOptionValue}"]:not([clone="true"])`);
                tag.after(tag.clone().attr("selected", true).attr("clone", true));
            })

            target.find(`option:not([clone="true"])`).removeAttr("selected");
            target.find(`option:not([clone="true"])`).prop("selected", false);

            target.trigger("chosen:updated");
        });
    });
}
